import React, {useState} from "react";
import {Box, Center, Heading, Stack, useBreakpointValue,} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {TextMentions} from "../mentions/TextMentions";
import {SlideIn} from "../../components/SlideIn";
import {Header} from "../../components/Layout/Header";
import {Footer} from "../../components/Layout/Footer";
import {ButtonsValider} from "../../components/Buttons/ButtonsValider";
import {useUpdateUserCgu} from "../utilisateur/services/useUpdateUserCgu";

export function PageCgu() {
    const [hoverColor, setHoverColor] = useState({
        isHover: false,
    });

    const navigate = useNavigate();
    const pageColor = "#36A9E1";
    const {t} = useTranslation();
    const isMobile = useBreakpointValue({base: true, md: false});
    const successCallback = () => navigate(`/dashboard`, {
        replace: true,
    });

    const {mutate: updateUser, isLoading: isLoadingUpdate} = useUpdateUserCgu({
        successCallback
    });

    const handleUpdate = () => {
        updateUser({
            cguOk: true
        });
    };

    return (
        <SlideIn>
            <Header/>
            <Box p={4} w={isMobile ? "100%" : "70%"} mx="auto">
                <Stack spacing={2}>
                    <Heading color="white">{t('app:core.cgu')}</Heading>
                    <TextMentions/>
                    <Stack m={4}>
                        <Center>
                            <ButtonsValider
                                minW="80px"
                                className={hoverColor.isHover ? "logoHover" : ""}
                                onMouseEnter={() => setHoverColor({isHover: true})}
                                onMouseLeave={() => setHoverColor({isHover: false})}
                                onClick={() => {
                                    if (!isLoadingUpdate) {
                                        handleUpdate();
                                    }
                                }}
                                isDisabled={isLoadingUpdate}
                                isLoading={isLoadingUpdate}
                                color={pageColor}
                            />
                        </Center>
                    </Stack>
                </Stack>

            </Box>
            <Footer/>
        </SlideIn>

    );
}
