import {IconButton} from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import {IconValider} from "components/Icons";

const propTypes = {
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export function ButtonsValider(props) {
    const {
        color = "white",
        isLoading = false,
        isDisabled = false,
        onMouseEnter,
        onMouseLeave,
        onClick,
        className = "",
        ...rest
    } = props;

    return (
        <IconButton
            _hover={{
                backgroundColor: "transparent",
            }}
            color={color}
            isDisabled={isDisabled}
            isLoading={isLoading}
            background="transparent"
            border="none"
            w="50px"
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            icon={<IconValider color={color} {...rest} />}
        />
    );
}

ButtonsValider.propTypes = propTypes;
