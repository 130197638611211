import React from "react";
import {Stack, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {ButtonWithIco} from "../../../components/Buttons/ButtonWithIco";
import IconMain from "../../../components/Icons/icons-generated/IconMain";


export function FormEnvoyerDons() {
    const {t} = useTranslation();
    const pageColor = "#E71D73";
    const navigate = () => {
        // new tab
        window.open("https://www.paypal.com/donate?hosted_button_id=YA6KKJYWPUVXL&locale.x=fr_FR", "_blank");
    }

    return <Stack mb={8}>
        <Stack>
            <Text fontSize="16px" color="white">
                {t("dons:envoyerText")}
            </Text>

            <Stack alignItems="center">
                <ButtonWithIco
                    textTransform="uppercase"
                    onClick={() => navigate()}
                    color={pageColor}
                    text={t("app:menu.envoyerUnDon")}
                    icon={<IconMain color={pageColor}/>}
                />
            </Stack>
        </Stack>
    </Stack>
}
