import {useToast} from "@chakra-ui/react";
import Axios from "axios";
import {MyToast} from "components/Toast/MyToast";
import {APPENV} from "config/config";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-query";
import {useLanguageContext} from "../../../context/lang-context";

export const useCreateDons = ({onSuccess, onError}) => {
    const toast = useToast()
    const {t} = useTranslation();
    const {lang} = useLanguageContext();

    return useMutation(
        (data) =>
            Axios.post(
                `${APPENV.HOST_RB_API}/dons?lang=${lang}`,
                data
            ),
        {
            onError: (error) => {
                let descriptionError = "dons:errors.createText"
                if (error?.response?.request.response) {
                    const errorObj = JSON.parse(error?.response?.request.response);
                    if (
                        errorObj.statusCode === 501 &&
                        errorObj?.error?.type === "AppException"
                    ) {
                        descriptionError = errorObj?.error?.description;
                    }
                }
                onError && onError();
                toast({
                    duration: 9000,
                    position: "top",
                    render: () => (
                        <MyToast status="error" backgroundColor="#EA5B0C" color="white" title={t('dons:errors.create')} text={t(descriptionError)}/>
                    ),
                });

            },
            onSuccess: async ({data}) => {
                /* toast({
                    duration: 9000,
                    position: "top",
                    render: () => (
                     ::   <MyToast status="success" backgroundColor="#EA5B0C" color="white" title={t('dons:success.create')} text={t('dons:success.createText')}/>
                    ),
                }); */
                if (onSuccess) {
                    await onSuccess(data);
                }

            },
        }
    );

};
