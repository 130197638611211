import React, {useState} from "react";
import {Alert, Stack, Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr, useBreakpointValue,} from "@chakra-ui/react";
import {ButtonWithIco} from "components/Buttons/ButtonWithIco";
import IconDoubleMains from "components/Icons/icons-generated/IconDoubleMains";
import {useTranslation} from "react-i18next";
import {Formiz} from "@formiz/core";
import {FieldFactory} from "components/fields/FieldFactory";
import {useGetPaysAdmin} from "App/register/services/useGetPaysAdmin";
import {useLocation} from "react-router";
import {useGetUsers} from "App/utilisateur/services/useGetUsers";
import {useGetCollectifsByPaysId} from "App/collectifs/services/useGetCollectifsByPaysId";
import {useUpdatePays} from "../services/useUpdatePays";
import {Row} from "./Row";
import {FormCollectifsAdmin} from "./FormCollectifsAdmin";

function FormUsers() {
    const {t} = useTranslation();
    const {search} = useLocation();
    const [params, setParams] = useState(new URLSearchParams(search).toString());
    const isMobile = useBreakpointValue({base: true, md: false});
    const [selectedPays, setSelectedPays] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState("admin");
    const {pays, isLoading: isLoadingPays} = useGetPaysAdmin();

    const handleFiltersPays = (paysId) => {
        const newParams = new URLSearchParams(params);
        newParams.set("pays", paysId);
        setSelectedPays(pays.filter((p) => p.id === paysId)[0]);
        setParams(newParams.toString());
    };
    const {users} = useGetUsers({params});
    const {users: allRepresentant} = useGetUsers({params: new URLSearchParams("statut=0")});

    const {mutate: updatePays} = useUpdatePays();
    const handleChange = (newCollectif) => {
        const dataToUpdate = {
            ...selectedPays,
            newCollectif,
        };
        updatePays(dataToUpdate);
    };

    const handleChangeRepresentant = (newRepresentant) => {
        const dataToUpdate = {
            ...selectedPays,
            newRepresentant,
        };
        updatePays(dataToUpdate);
    };

    const {collectifs, isLoading, isFetching} = useGetCollectifsByPaysId(selectedPays?.id);

    const pageColor = "#36A9E1";

    return (
        <>
            <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="center">
                <ButtonWithIco
                    onClick={() => {
                        setSelectedMenu("collectifs");
                    }}
                    textTransform="uppercase"
                    color={pageColor}
                    text="Administrer les tribus"
                    icon={<IconDoubleMains color={pageColor}/>}
                />
                <ButtonWithIco
                    onClick={() => {
                        setSelectedMenu("admin");
                    }}
                    textTransform="uppercase"
                    color={pageColor}
                    text="Dashboard Admin"
                    icon={<IconDoubleMains color={pageColor}/>}
                />
            </Stack>
            <Stack mx="auto" p={isMobile ? 4 : 12} minH="500px" w={isMobile ? "100%" : "80%"}>
                {selectedMenu === "collectifs" ? (
                    <FormCollectifsAdmin/>
                ) : (
                    <>
                        <Formiz>
                            <Stack spacing={8}>
                                <FieldFactory
                                    isLoading={isLoadingPays}
                                    type="select"
                                    name="localisation"
                                    options={pays?.map((p) => ({
                                        label: p.nom,
                                        value: p.id,
                                    }))}
                                    setParentValue={handleFiltersPays}
                                />
                                {selectedPays?.id && !isLoading && !isFetching ? (
                                    <Stack>
                                        <Alert>Modifier le collectif du pays.</Alert>
                                        <FieldFactory
                                            key={`collectifs_${selectedPays?.collectifId}_${selectedPays?.id}`}
                                            isLoading={isLoading}
                                            placeholder="Vous pouvez modifier le collectif du pays"
                                            type="select"
                                            name="collectifPays"
                                            options={collectifs?.map((c) => ({
                                                label: c.collectif.identite,
                                                value: c.collectif.id,
                                            }))}
                                            defaultValue={selectedPays?.collectifId}
                                            setParentValue={handleChange}
                                        />
                                    </Stack>
                                ) : null}

                                {selectedPays?.id && !isLoading && !isFetching ? (
                                    <Stack>
                                        <Alert>Modifier le représentant du pays.</Alert>
                                        <FieldFactory
                                            key={selectedPays?.id}
                                            isLoading={isLoading}
                                            placeholder="Vous pouvez modifier le représentant du pays"
                                            type="select"
                                            name="representantPays"
                                            options={allRepresentant?.map((c) => ({
                                                label: c.identite,
                                                value: c.id,
                                            }))}
                                            defaultValue={selectedPays?.representantId}
                                            setParentValue={handleChangeRepresentant}
                                        />
                                    </Stack>
                                ) : null}
                            </Stack>
                        </Formiz>
                        <TableContainer overflow="scroll">
                            <Table variant="simple">
                                <TableCaption>{t("account:admin.utilisateurs")}</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th minW="300px">{t("account:identite")}</Th>
                                        <Th minW="300px">{t("account:mail")}</Th>
                                        <Th>{t("account:profil.title")}</Th>
                                        <Th minW="300px">{t("account:siteWeb")}</Th>
                                        <Th>{t("account:statut")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {users?.map((usr) => (
                                        <Row key={usr.id} user={usr}/>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Stack>
        </>
    );
}

export {FormUsers};
