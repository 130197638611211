import {ErrorBoundary} from 'components/ErrorBoundary';
import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuthentificationContext} from 'context/auth-context';

export function AuthenticatedRouteGuard({
                                            children,
                                        }) {
    const {user, isAuthenticated} = useAuthentificationContext();
    const {pathname, search} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(`/login?redirect=${encodeURIComponent(pathname + search)}`, {
                replace: true,
            });
        }
        if (user?.cguOk === false) {
            navigate(`/cgu`, {
                replace: true,
            });
        }
    }, [user, isAuthenticated, navigate, pathname, search]);


    return !isAuthenticated ? null : <ErrorBoundary>{children}</ErrorBoundary>;
}

AuthenticatedRouteGuard.propTypes = {
    children: PropTypes.node,
};
