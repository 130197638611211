import React, {useState} from "react";
import {Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Grid, GridItem, HStack, Stack, StackDivider, Text, useBreakpointValue, useDisclosure,} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import IconBurger from "components/Icons/icons-generated/IconBurger";
import {LanguagePicker} from "components/Language/LanguagePicker";
import {LogoBis} from "components/LogoBis";
import {CloseIcon} from "@chakra-ui/icons";
import {useAuthentificationContext} from "context/auth-context";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import {useApp} from "context/App/useApp";
import {ButtonsCopy} from "components/Buttons/ButtonsCopy";
import {Item} from "./Item";

export function MenuDrawer() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {t} = useTranslation();
    const {user, isLoading} = useAuthentificationContext();
    const {pageColor, colorNotif, colorNotifHover} = useApp();
    const [hoverColor, setHoverColor] = useState({
        isHover: false,
    });
    const isMobile = useBreakpointValue({base: true, md: false});
    const blueColor = "#36A9E1";

    if (isLoading) {
        return null;
    }
    if (!isMobile) {
        return (
            <>
                {isOpen ? (
                    <CloseIcon w="30px" color="white" onClick={onClose}/>
                ) : (
                    <IconBurger
                        color={pageColor}
                        className={hoverColor.isHover ? "logoHover" : ""}
                        onMouseEnter={() => setHoverColor({isHover: true})}
                        onMouseLeave={() => setHoverColor({isHover: false})}
                        _hover={{cursor: "pointer"}}
                        onClick={onOpen}
                        h="30px"
                    />
                )}

                <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
                    <DrawerContent size="md" marginTop="120px" mx="auto" w="80%">
                        <DrawerBody bgColor={blueColor} p={0}>
                            <Stack spacing={8} mb={16}>
                                <Stack>
                                    <Stack background="rgba(0, 0, 0, 0.4);" spacing={0} p={2} position="relative">
                                        <LanguagePicker
                                            color={colorNotif}
                                            defaultPos={{
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                            }}
                                        />
                                        <HStack alignSelf="center" alignItems="center">
                                            <Text color={colorNotif}>{`${t("account:energieDisponible")}`} </Text>
                                            <IconEnergieSansPlus color={colorNotif}/>
                                            <Text color={colorNotif}>{user?.energiesDisponible ?? 0} </Text>
                                        </HStack>
                                    </Stack>
                                    <HStack background="rgba(0, 0, 0, 0.2);" p={2} justifyContent="center">
                                        <Text color={colorNotif} textAlign="center" fontSize="14px">{`${t(
                                            "account:cle"
                                        )} : ${user?.clePublique ?? ""}`}</Text>
                                        <ButtonsCopy copyText={user?.clePublique ?? ""}/>
                                    </HStack>
                                </Stack>

                                <Grid
                                    templateColumns="repeat(3, 1fr)"
                                    textAlign="left"
                                    alignSelf="center"
                                    margin="auto"
                                    w="80%"
                                    fontSize="20px"
                                    gap={6}
                                    divider={<StackDivider border="1px solid rgba(255, 255, 255, 0.5);"/>}
                                >
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            to="/account/proposerDon"
                                            onClose={onClose}
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.proposerUnDon")}
                                        />
                                    </GridItem>
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            onClose={onClose}
                                            to="/dashboard/historique"
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.historiqueDesDons")}
                                        />
                                    </GridItem>
                                    {/* <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                    <Item
                      to="/account/friend"
                      onClose={onClose}
                      color={colorNotif}
                      colorHover={colorNotifHover}
                      text={t("app:menu.inviterUnAmi")}
                    />
                  </GridItem> */}
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            to="/dashboard/dons"
                                            onClose={onClose}
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.rechercherUnDon")}
                                        />
                                    </GridItem>
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            onClose={onClose}
                                            color={colorNotif}
                                            to="/dashboard/favoris"
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.favoris")}
                                        />
                                    </GridItem>
                                    {/* <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                    <Item
                      onClose={onClose}
                      to="/account/profil"
                      color={colorNotif}
                      colorHover={colorNotifHover}
                      text={t("app:menu.profil")}
                    />
                  </GridItem> */}
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            to="/donsEnergie"
                                            onClose={onClose}
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.envoyerUnDon")}
                                        />
                                    </GridItem>
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            to="/collectifs"
                                            onClose={onClose}
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.collectifsDesDonateurs")}
                                        />
                                    </GridItem>
                                    <GridItem h="30px" w="100%" borderBottom="solid 1px rgba(255, 255, 255, .2)">
                                        <Item
                                            to="/dashboard/aide"
                                            onClose={onClose}
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.aide")}
                                        />
                                    </GridItem>
                                    <GridItem
                                        h="30px"
                                        w="100%"
                                        colSpan={3}
                                        borderBottom="solid 1px rgba(255, 255, 255, .2)"
                                    >
                                        <Item
                                            onClose={onClose}
                                            to="/logout"
                                            fontWeight="bold"
                                            color={colorNotif}
                                            colorHover={colorNotifHover}
                                            text={t("app:menu.deconnexion")}
                                        />
                                    </GridItem>
                                </Grid>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        );
    }

    return (
        <>
            <IconBurger
                color={pageColor}
                className={hoverColor.isHover ? "logoHover" : ""}
                onMouseEnter={() => setHoverColor({isHover: true})}
                onMouseLeave={() => setHoverColor({isHover: false})}
                _hover={{cursor: "pointer"}}
                onClick={onOpen}
                h="30px"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%);"
            />
            <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay/>
                <DrawerContent mx={4} w="" bgColor={blueColor}>
                    <DrawerHeader color="#B0B0B0">
                        <Stack position="relative">
                            <HStack alignSelf="center">
                                <LogoBis color={colorNotif} h="80px"/>
                            </HStack>
                            <LanguagePicker
                                color={colorNotif}
                                defaultPos={{
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                }}
                            />
                            <Stack alignItems="center">
                                <CloseIcon color={colorNotif} onClick={onClose}/>
                            </Stack>
                        </Stack>
                    </DrawerHeader>
                    <DrawerBody p={0}>
                        <Stack pt={2}>
                            <Stack p={2} background="rgba(0, 0, 0, 0.4);">
                                <HStack justifyContent="center">
                                    <Text textTransform="uppercase" color={colorNotif}>
                                        {`${t("account:energieDisponible")}`}{" "}
                                    </Text>
                                    <IconEnergieSansPlus color={colorNotif}/>
                                    <Text color={colorNotif}>{user?.energiesDisponible ?? 0}</Text>
                                </HStack>
                            </Stack>
                            <HStack background="rgba(0, 0, 0, 0.2);" p={2} justifyContent="center">
                                <Text color={colorNotif} textAlign="center" fontSize="14px">{`${t(
                                    "account:cle"
                                )} : ${user?.clePublique ?? ""}`}</Text>
                                <ButtonsCopy copyText={user?.clePublique ?? ""}/>
                            </HStack>

                            <Stack
                                p={2}
                                fontSize="20px"
                                alignItems="start"
                                divider={<StackDivider border="1px solid rgba(255, 255, 255, 0.5);"/>}
                            >
                                <Item
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    to="/account/proposerDon"
                                    text={t("app:menu.proposerUnDon")}
                                    onClose={onClose}
                                />
                                <Item
                                    to="/dashboard/dons"
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    text={t("app:menu.rechercherUnDon")}
                                    onClose={onClose}
                                />
                                <Item
                                    to="/donsEnergie"
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    text={t("app:menu.envoyerUnDon")}
                                    onClose={onClose}
                                />
                                <Item
                                    to="/dashboard/historique"
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    text={t("app:menu.historiqueDesDons")}
                                    onClose={onClose}
                                />
                                <Item
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    to="/dashboard/favoris"
                                    text={t("app:menu.favoris")}
                                    onClose={onClose}
                                />
                                <Item
                                    to="/collectifs"
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    text={t("app:menu.collectifsDesDonateurs")}
                                    onClose={onClose}
                                />

                                {/* <Item
                  to="/account/friend"
                  color={colorNotif}
                  colorHover={colorNotifHover}
                  text={t("app:menu.inviterUnAmi")}
                  onClose={onClose}
                /> */}
                                {/* <Item
                  to="/account/profil"
                  color={colorNotif}
                  colorHover={colorNotifHover}
                  text={t("app:menu.profil")}
                  onClose={onClose}
                /> */}
                                <Item
                                    to="/dashboard/aide"
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    text={t("app:menu.aide")}
                                    onClose={onClose}
                                />
                                <Item
                                    fontWeight="700"
                                    to="/logout"
                                    color={colorNotif}
                                    colorHover={colorNotifHover}
                                    text={t("app:menu.deconnexion")}
                                    onClose={onClose}
                                />
                            </Stack>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}
