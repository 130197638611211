import {Button, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import PropTypes from "prop-types";

const propTypes = {
    icon: PropTypes.node,
    onClick: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    lineHeight: PropTypes.string,
    fontSize: PropTypes.string,
    text: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export function ButtonWithIco(props) {
    const {
        icon = null,
        isLoading = false,
        lineHeight = "20px",
        fontSize = "16px",
        isDisabled = false,
        color = "white",
        className = "",
        text = "",
        onClick,
        ...rest
    } = props;
    const [hoverColor, setHoverColor] = useState(color);

    let ico = null;
    if (icon) {
        ico = React.cloneElement(icon, {
            color: hoverColor,
        });
    }

    return (
        <Button
            minH={50}
            _hover={{
                backgroundColor: "energieBlack.500",
                borderColor: "white",
                color: "white",
            }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            backgroundColor="energieBlack.500"
            borderRadius="30px"
            borderColor={color}
            color={color}
            className={className}
            onMouseEnter={() => setHoverColor("#FFFFFF")}
            onMouseLeave={() => setHoverColor(color)}
            leftIcon={ico ?? null}
            onClick={onClick}
            {...rest}
        >
            {text ? (
                <Text fontSize={fontSize} lineHeight={lineHeight}>
                    {text}
                </Text>
            ) : null}
        </Button>
    );
}

ButtonWithIco.propTypes = propTypes;
