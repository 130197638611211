import React, {useState} from "react";
import {Button, Center, HStack, IconButton, Stack, StackDivider, Text, useBreakpointValue, useDisclosure,} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FaFacebookF, FaLinkedinIn, FaYoutube} from "react-icons/fa";
import {LogoBisFooter} from "components/LogoBisFooter";
import {RxCaretRight} from "react-icons/rx";
import {ModalCustom} from "components/Modal/Modal";
import {TextMentions} from "App/mentions/TextMentions";

export function Footer() {
    const {t} = useTranslation();
    const isMobile = useBreakpointValue({base: true, md: false});
    const {
        isOpen: isOpenMentions,
        onClose: onCloseMentions,
        onOpen: onOpenMentions,
    } = useDisclosure();
    const {isOpen, onClose, onOpen} = useDisclosure();
    const [hoverColor, setHoverColor] = useState({
        isHover: false,
    });
    if (isMobile) {
        return (
            <Stack
                w="100%"
                position="sticky"
                clear="both"
                height="170px"
                borderTop="solid 1px"
                borderBottom="solid 1px"
            >
                <ModalCustom
                    title={t("account:reseaux.modalTitle")}
                    textDescription={t("account:reseaux.modalDescription")}
                    color="white"
                    bgColor="energieRose"
                    isOpen={isOpen}
                    onClose={onClose}
                    handleValider={(e) => {
                        window.location.href = "mailto:pierre@divinenergy.info";
                        e.preventDefault();
                    }}
                    className={hoverColor.isHover ? "logoHover" : ""}
                    onMouseEnter={() => setHoverColor({isHover: true})}
                    onMouseLeave={() => setHoverColor({isHover: false})}
                />
                <HStack spacing={4} justifyContent="center">
                    <Button w="80px" h="50px" as={Link} to="/" variant="link">
                        <LogoBisFooter w="80px" h="50px"/>
                    </Button>
                    <HStack
                        alignItems="start"
                        divider={
                            <StackDivider
                                alignSelf="center"
                                h="80px"
                                border="1px solid rgba(255, 255, 255, 0.5);"
                            />
                        }
                    >
                        <Stack>
                            <Button as={Link} to="/divinenergie" variant="linkFooterMb">
                                <RxCaretRight fontSize="30px"/>
                                <Text fontSize="14px"> {`${t("app:core.leProjet")}`}</Text>
                            </Button>
                            <Button as={Link} to="/partenaires" variant="linkFooterMb">
                                <RxCaretRight fontSize="30px"/>
                                <Text fontSize="14px">{`${t("app:core.nosPartenaires")}`}</Text>
                            </Button>
                            <Button
                                as={Link}
                                to="#"
                                onClick={(e) => {
                                    window.location.href = "mailto:pierre@divinenergy.info";
                                    e.preventDefault();
                                }}
                                variant="linkFooterMb"
                            >
                                <RxCaretRight fontSize="30px"/>
                                <Text fontSize="14px"> {`${t("app:core.contact")}`}</Text>
                            </Button>
                        </Stack>
                        <Stack mt={2} alignSelf="center">
                            <IconButton
                                aria-label="Linkedin"
                                icon={<FaLinkedinIn/>}
                                as={Link}
                                onClick={onOpen}
                                variant="linkFooterIco"
                            />
                            <IconButton
                                aria-label="Facebook"
                                icon={<FaFacebookF/>}
                                as={Link}
                                onClick={onOpen}
                                variant="linkFooterIco"
                            />
                            <IconButton
                                aria-label="Youtube"
                                icon={<FaYoutube/>}
                                as={Link}
                                onClick={onOpen}
                                variant="linkFooterIco"
                            />
                        </Stack>
                    </HStack>
                </HStack>
                <ModalCustom
                    title={t("app:core.mentionswodroits")}
                    textDescription=""
                    color="white"
                    bgColor="energieCyan"
                    isOpen={isOpenMentions}
                    onClose={onCloseMentions}
                    withFooter={false}
                >
                    <TextMentions/>
                </ModalCustom>
                <Center>
                    <Text onClick={onOpenMentions} color="#B0B0B0" fontSize="10px" variant="linkFooter">{`${t(
                        "app:core.mentions"
                    )}`}</Text>
                </Center>
            </Stack>
        );
    }

    return (
        <Stack alignSelf="center" w="80%" height="170px" borderTop="solid 1px" borderBottom="solid 1px">
            <ModalCustom
                title={t("account:reseaux.modalTitle")}
                textDescription={t("account:reseaux.modalDescription")}
                color="white"
                bgColor="energieRose"
                isOpen={isOpen}
                onClose={onClose}
                handleValider={(e) => {
                    window.location.href = "mailto:pierre@divinenergy.info";
                    e.preventDefault();
                }}
                className={hoverColor.isHover ? "logoHover" : ""}
                onMouseEnter={() => setHoverColor({isHover: true})}
                onMouseLeave={() => setHoverColor({isHover: false})}
            />
            <HStack
                divider={
                    <StackDivider alignSelf="center" h="50px" border="1px solid rgba(255, 255, 255, 0.5);"/>
                }
                minH="100px"
                w="100%"
                borderTop="solid 1px"
                borderBottom="solid 1px"
                direction="column"
            >
                <HStack flex={4} justifyContent="space-evenly">
                    <Button
                        w="80px"
                        h="50px"
                        as={Link}
                        to="/"
                        size="footerLink"
                        variant="link"
                        whiteSpace="initial"
                    >
                        <LogoBisFooter/>
                    </Button>

                    <Button as={Link} to="/divinenergie" variant="linkFooter">
                        <RxCaretRight fontSize="30px"/>
                        <Text fontSize="14px">{`${t("app:core.leProjet")}`}</Text>
                    </Button>
                    <Button as={Link} to="/partenaires" variant="linkFooter">
                        <RxCaretRight fontSize="30px"/>
                        <Text fontSize="14px">{`${t("app:core.nosPartenaires")}`}</Text>
                    </Button>
                    <Button
                        as={Link}
                        to="#"
                        onClick={(e) => {
                            window.location.href = "mailto:pierre@divinenergy.info";
                            e.preventDefault();
                        }}
                        variant="linkFooterMb"
                    >
                        <RxCaretRight fontSize="30px"/>
                        <Text fontSize="14px">{`${t("app:core.contact")}`}</Text>
                    </Button>
                </HStack>
                <HStack flex={1} justifyContent="space-evenly">
                    <IconButton
                        aria-label="Linkedin"
                        icon={<FaLinkedinIn/>}
                        as={Link}
                        onClick={onOpen}
                        variant="linkFooterIco"
                    />
                    <IconButton
                        aria-label="Facebook"
                        icon={<FaFacebookF/>}
                        as={Link}
                        onClick={onOpen}
                        variant="linkFooterIco"
                    />
                    <IconButton
                        aria-label="Youtube"
                        icon={<FaYoutube/>}
                        as={Link}
                        onClick={onOpen}
                        variant="linkFooterIco"
                    />
                </HStack>
            </HStack>
            <ModalCustom
                withFooter={false}
                title={t("app:core.mentionswodroits")}
                textDescription=""
                color="white"
                bgColor="energieCyan"
                isOpen={isOpenMentions}
                onClose={onCloseMentions}
            >
                <TextMentions/>
            </ModalCustom>
            <Center _hover={{cursor: "pointer"}}>
                <Text
                    _hover={{cursor: "pointer"}}
                    onClick={onOpenMentions}
                    color="#B0B0B0"
                    fontSize="10px"
                    variant="linkFooter"
                >{`${
                    t("app:core.mentions", {
                        annee: new Date().getFullYear()
                    })}`}</Text>
            </Center>
        </Stack>
    );
}
