import React, {useEffect} from "react";
import {Heading, SimpleGrid, Stack} from "@chakra-ui/react";
import {SlideIn} from "components/SlideIn";
import {useApp} from "context/App/useApp";
import {useTranslation} from "react-i18next";
import {Header} from "components/Layout/Header";
import {Footer} from "components/Layout/Footer";
import {CardPartenaire} from "./_partials/CardPartenaire";

export function PagePartenaires() {
    const {t} = useTranslation();
    const {setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover} = useApp();

    useEffect(() => {
        setPageColor("#36A9E1");
        setBackgroundColorNotif("#36A9E1");
        setColorNotif("#FFFFFF");
        setColorNotifHover("#000000");
    }, [setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover]);

    return (
        <SlideIn>
            <Header/>
            <Heading mx="auto" fontSize="24px" color="energieCyan" textTransform="uppercase">
                {t("partenaires:title")}
            </Heading>
            <Stack mx="auto" p={8}>
                <SimpleGrid columns={[1, 2]} spacing="40px">
                    <CardPartenaire
                        src="/logo-bleu.jpg"
                        href="https://t.me/revolution_canape_canal"
                        collectif="R-ÉVOLUTION"
                    />
                    <CardPartenaire
                        src="/esseniens.jpg"
                        href="https://esseniens.org/"
                        collectif="Esseniens"
                    />
                </SimpleGrid>
            </Stack>
            <Footer/>
        </SlideIn>
    );
}
